import { i18nUtil } from '@/utils'
import RouteNames from '@/consts/RouteNames'
import { LocationQueryRaw } from 'vue-router'
export interface ViewItemInter {
  title: string
  item: {
    itemTitle: string
    router: string
    query?: LocationQueryRaw
  }[]
}
// 天然产物
export const NatureViewItem: ViewItemInter[] = [
  {
    title: i18nUtil.T('common.nature'),
    item: [
      // { itemTitle: i18nUtil.T('common.BatchUpload'), router: 'BS-Home' },
      { itemTitle: i18nUtil.T('common.DataStatistic'), router: RouteNames.Nature.DataStatisticPage },
      { itemTitle: i18nUtil.T('common.StructureDraw'), router: RouteNames.Compound.StructureDrawPage },
      { itemTitle: i18nUtil.T('nature.OrganismsTaxonomy'), router: RouteNames.Nature.Taxonomy },
      { itemTitle: i18nUtil.T('nature.Browser'), router: RouteNames.Nature.BrowserPage, query: { page: 1, type: 'compound' } },
    ],
  },
]
// 蛋白质库
export const ProteinViewItem: ViewItemInter[] = [
  {
    title: i18nUtil.T('common.protein'),
    item: [
      // { itemTitle: i18nUtil.T('protein.functionNav.Batch'), router: 'BS-Home' },
      { itemTitle: i18nUtil.T('protein.functionNav.Blast'), router: 'blastP' },
      { itemTitle: i18nUtil.T('protein.functionNav.Msa'), router: RouteNames.Home.MSA },
      // { itemTitle: i18nUtil.T('protein.functionNav.phylogenetictree'), router: RouteNames.Protein.ClusterAnalysis },
      { itemTitle: i18nUtil.T('protein.functionNav.AnalysisPhyPro'), router: RouteNames.Protein.PhysicochemicalPropertyAnalyze },
      { itemTitle: i18nUtil.T('protein.virtualScreening'), router: RouteNames.Protein.VirtualFiltering },
      { itemTitle: i18nUtil.T('protein.functionNav.DataStatistic'), router: RouteNames.Protein.DataStatisticPage },
    ],
  },
]
// 化合物
export const CompoundViewItem: ViewItemInter[] = [
  {
    title: i18nUtil.T('common.compound'),
    item: [
      // { itemTitle: i18nUtil.T('nav.batchSearch'), router: '' },
      { itemTitle: i18nUtil.T('common.compoundRetrosynthesis'), router: RouteNames.Compound.InverseSynthesis },
      { itemTitle: i18nUtil.T('common.StructureDraw'), router: RouteNames.Compound.StructureDrawPage },
      { itemTitle: i18nUtil.T('common.targetPrediction'), router: RouteNames.Compound.TargetPredictionPage },
      { itemTitle: i18nUtil.T('nav.Resource.SimilarCompound'), router: RouteNames.Compound.SimilarCompoundPage },
      { itemTitle: i18nUtil.T('nav.ADMET'), router: RouteNames.Compound.AdmetPrediction },
      { itemTitle: i18nUtil.T('breadcrumb.PeriodicTable'), router: RouteNames.Compound.PeriodicTablePage },
      { itemTitle: i18nUtil.T('common.DataStatistic'), router: RouteNames.Compound.DataStatisticPage },
    ],
  },
]
// 基因
export const GeneViewItem: ViewItemInter[] = [
  {
    title: i18nUtil.T('gene.gene'),
    item: [
      // { itemTitle: i18nUtil.T('nav.batchSearch'), router: '' },
      { itemTitle: i18nUtil.T('gene.Blast'), router: 'blastN' },
      { itemTitle: 'Primer' + i18nUtil.T('nav.Resource.PrimerDesign'), router: RouteNames.Gene.PrimerUploadDetail },
      { itemTitle: i18nUtil.T('nav.Resource.OpenRead'), router: RouteNames.Gene.ORFFinder },
      { itemTitle: 'JBrowse', router: RouteNames.Gene.JBrowse },
      { itemTitle: i18nUtil.T('nav.Protects'), router: RouteNames.Gene.nucleobase },
      { itemTitle: i18nUtil.T('nav.Restriction'), router: RouteNames.Gene.ResetMap },
      { itemTitle: i18nUtil.T('common.DataStatistic'), router: RouteNames.Gene.DataStatisticPage },
    ],
  },
]
// 其他
export const OtherViewItem: ViewItemInter[] = [
  {
    title: i18nUtil.T('homeSearch.Other'),
    item: [
      { itemTitle: i18nUtil.T('homeSearch.Upload'), router: RouteNames.Upload.Upload },
      { itemTitle: i18nUtil.T('homeSearch.DownLoad'), router: RouteNames.Home.HomeDownload },
      { itemTitle: i18nUtil.T('homeSearch.KnowledgeGraph'), router: RouteNames.Home.knowledgeAtlas },
      { itemTitle: i18nUtil.T('homeSearch.AISpecialistLifeChemistry'), router: RouteNames.Home.chatgpt },
      { itemTitle: i18nUtil.T('nav.batchSearch'), router: 'BS-Home' },
      { itemTitle: i18nUtil.T("nav.feedback"), router: RouteNames.Home.feedback }
    ],
  },
]

// 全部的内容
export const AllViewItem: ViewItemInter[] = [...NatureViewItem, ...ProteinViewItem, ...CompoundViewItem, ...GeneViewItem, ...OtherViewItem]

export default class Service { }
