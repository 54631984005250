import { ref } from 'vue'
import { defineStore } from 'pinia'

export interface SearchHistoryArrInter {
  ids: (string | number)[]
  item: string
  type: string
}
export const useMainHome = defineStore(
  'main-home',
  () => {
    //大主页的搜索历史的持久化
    const searchHistoryArr = ref<SearchHistoryArrInter[]>([])
    // 推入数据
    function unshiftSearchHistory(item: SearchHistoryArrInter) {
      // 需要先检查数据是否重复
      const value = searchHistoryArr.value.find((itemx) => {
        return itemx.ids.toString() === item.ids.toString() && itemx.item === item.item
      })
      // 有值
      if (value) return
      // 超过数据
      if (searchHistoryArr.value.length >= 10) searchHistoryArr.value.pop()
      // 执行推入
      searchHistoryArr.value.unshift(item)
    }

    /* ----------------另一个不同的逻辑----------------------- */
    // 工具栏的搜索历史持久化
    const searchHistory = ref<string[]>([])

    return { searchHistoryArr, unshiftSearchHistory, searchHistory }
  },
  { persist: { enabled: true, strategies: [{ storage: localStorage }] } }
)
