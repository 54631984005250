<template>
  <div class="flex flex-wrap items-center">
    <el-dropdown @command="handleCommand">
      <el-button >
        {{ langText }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="zh">简体中文</el-dropdown-item>
          <el-dropdown-item command="en">English</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script lang="ts" setup>
import { watch, computed } from 'vue'
import { ArrowDown } from '@element-plus/icons-vue'
import { useI18n } from 'vue-i18n'
const { locale } = useI18n()

watch(
  () => locale.value,
  (lang) => {
    if (lang) {
      localStorage.setItem('Custom-Language', lang) // 存放到本地缓存
      location.reload() // 刷新页面实现重发api请求
    }
  }
)

const langText = computed(() => (locale.value == 'zh' ? '简体中文' : 'English'))

//语言切换事件
const handleCommand = (command: string) => {
  locale.value = command
}
</script>
<style scoped>
.example-showcase .el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;

}


</style>
