import { computed, ref, watch } from 'vue'
import { useStore } from '@/store/store'
import { routeUtil, i18nUtil } from '@/utils'
import RouteNames from '@/consts/RouteNames'

type MainRouteKey = 'protein' | 'compound' | 'nature' | 'gene' | 'home'
type MainRouteItem = { url: string; title: string }
type MainRoute = {
  [key in MainRouteKey]: MainRouteItem
}
// 主要路由
const mapToUrl: MainRoute = {
  protein: { url: RouteNames.Protein.HomePage, title: i18nUtil.T('protein.protein') },
  compound: { url: RouteNames.Compound.HomePage, title: i18nUtil.T('compound.compound') },
  nature: { url: RouteNames.Nature.HomePage, title: i18nUtil.T('common.nature') },
  gene: { url: RouteNames.Gene.HomePage, title: i18nUtil.T('gene.gene') },
  home: { url: RouteNames.Home.HomePage, title: i18nUtil.T('home.nucleobase.homePage') },
}
// 关于我们
const aboutUS: MainRoute = {
  protein: { url: RouteNames.Protein.AboutUsPage, title: i18nUtil.T('nav.about') },
  compound: { url: RouteNames.Compound.AboutUsPage, title: i18nUtil.T('nav.about') },
  gene: { url: RouteNames.Gene.AboutUsPage, title: i18nUtil.T('nav.about') },
  nature: { url: RouteNames.Nature.AboutUsPage, title: i18nUtil.T('nav.about') },
  home: { url: RouteNames.Home.AboutUs, title: i18nUtil.T('nav.about') },
}

// 静态路由
export const staticRouter: { url: string; title: string }[] = [
  { url: RouteNames.Home.chatgpt, title: i18nUtil.T('nav.AI') },
  { url: RouteNames.Home.knowledgeAtlas, title: i18nUtil.T('nav.knowledgeGraph') },
]
// 全局控制面板的值

export class Service {
  store = useStore()
  modal = ref(false)
  allRouterArr = ref<MainRouteItem[]>([])
  // 是home 页面
  computedIsHome = computed(() => routeUtil.route.path === '/home')

  constructor() {
    this.watchRoutePath()
  }
  // 获取当前路由的第一个路径 例如：/protein/... 返回 protein
  get firstPath() {
    // 获取到第一个路径
    const path = routeUtil.route.path.split('/')[1] as MainRouteKey
    // 符合这个参数，就返回
    if (path in mapToUrl) return path
    // 这是其余的，类似 /AboutUs 开头的，就返回首页的标识
    return 'home'
  }
  // 获取到当前路由的第一个路径对应的路由信息
  get firstRouter() {
    return mapToUrl[this.firstPath]
  }
  // 获取到关于我们的路由信息
  get aboutUsRouter() {
    return aboutUS[this.firstPath]
  }
  // 监听路由的变化
  private watchRoutePath = () => {
    watch(
      () => routeUtil.route.path,
      (val) => {
        this.allRouterArr.value = this.handleAllRouter(val)
      },
      {
        immediate: true,
      }
    )
  }
  // 放入一些路径信息
  private handleAllRouter = (path: string) => {
    const arr: MainRouteItem[] = []
    //放入第一个路由
    arr.push(this.firstRouter)
    // home 页面特有的
    if (path === '/home') {
      arr.push({ url: 'BS-Home', title: i18nUtil.T('BS.BatchSearch') }) //批量查找
      arr.push({ url: RouteNames.Upload.Upload, title: i18nUtil.T('homeSearch.DownLoad') }) // 下载
    }
    // 其余
    arr.push(...staticRouter)
    arr.push({ url: RouteNames.Home.instruction, title: i18nUtil.T('nav.instruction') })
    arr.push(this.aboutUsRouter)

    return arr
  }
}
