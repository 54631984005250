<template>
  <div class="view-item">
    <div>
      <div class="title">{{ data.title }}</div>
      <div class="title-bg"></div>
      <div class="content" v-for="itemContent in data.item">
        <div class="item mb-2" @click="onClickAction(itemContent.router, itemContent.query)">
          <span>{{ itemContent.itemTitle }}</span>
          <DArrowRight />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject } from 'vue'
import { DArrowRight } from '@element-plus/icons-vue'
import { ViewItemInter } from '../use-view-item'

import { routeUtil } from '@/utils'
defineProps<{
  data: ViewItemInter
}>()

const modal = inject('modal') as any

const onClickAction = (router: string, query: any) => {
  routeUtil.router.push({ name: router, query: query })
  modal.value = false
}
</script>

<style scoped lang="scss">
.view-item {
  color: #3775a9;

  .title {
    text-shadow: #3775a9 1px 0 3px;
    position: relative;
    left: -16px;
    top: 10px;
    color: #3775a9;
  }

  .title-bg {
    width: 80px;
    height: 20px;
    background-color: #94b4cf;
    margin-bottom: 30px;
  }

  .content {
    .item {
      cursor: pointer;
      border-radius: 3px;
      padding-left: 5px;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background-color: #3775a9;
        color: white;
      }
      border: 1px solid #3775a9;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
