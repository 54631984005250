import { objectToKeyValue } from '@/utils'
// 中文
const zhBaseInfo = {
  Example: '示例',
  Clear: '清除',
  GeneticCodons: '遗传密码子',
  Standard: '标准',
  Submit: '提交',
  GeneticCode: '遗传密码',
  StartCodon: '起始密码子',
  AllMarkDown: '全部取消标记',
  MarkNumber: '标记数',
  DownloadOptions: '下载选项',
  DownLoad: '下载',
  Null: '无',
  AllOrfs: '所有ORF',
  PositiveChain: '正链',
  MinusStrand: '负链',
  Label: '标签',
  Chain: '链',
  ReadingFrameNumber: '读码框编号',
  Start: '起始',
  End: '终止 ',
  Length: '长度',
  MarkUp: '标记',
  Unmark: '取消标记',
  ProteinSequence: '蛋白质序列',
  NucleotideSequence: '核苷酸序列',
}
const zhParagraphInfo = {
  tip01: '开放阅读框查找 (ORF Finder)',
  tip02:
    '说明：开放阅读框查找（ORF Finder）是一个图形化的序列分析工具，在开放阅读框查找工具中分析并查找序列中的ORF区（open reading frame）。 这个工具使用标准的或其他特殊的遗传密码查找序列中所有可能的ORF区，并推导出相应的氨基酸序列。',
  tip03: '选择检索的参数：',
  tip04: '最小ORF长度（nt）:',
  tip05: 'ORF起始密码子的使用：',
  tip06: '仅限"ATG"',
  tip07: '"ATG"和代替性起始密码子',
  tip08: '任何有意义的密码子',
  tip09: '检索到的ORF：',
  tip10: '选择要标记的子集：',
  tip11: '最长的6个ORF',
  tip12: '蛋白质序列FASTA',
  tip13: '将ORF显示为：',
  // tip14: '输入核苷酸的FASTA格式序列或YYNID号',
  tip14: '输入核苷酸的FASTA格式序列',
  tip15: '提示：双击下方表格数据可进行标记',
}
// 英文
const enBaseInfo = objectToKeyValue<keyof typeof zhBaseInfo>(zhBaseInfo, false)
const enParagraphInfo = objectToKeyValue<keyof typeof zhParagraphInfo>(zhParagraphInfo, true)

enParagraphInfo.tip01 = 'Open Reading Frame Finder (ORF Finder)'
enParagraphInfo.tip02 =
  'Description: ORF Finder is a graphical sequence analysis tool that analyzes and finds the open reading frame (ORF) region in the sequence in the open reading frame finding tool. This tool uses standard or other special genetic codes to find all possible ORF regions in a sequence and deduce the corresponding amino acid sequence.'
enParagraphInfo.tip03 = 'Select the parameters to retrieve:'
enParagraphInfo.tip04 = 'Minimum ORF length(nt):'
enParagraphInfo.tip05 = 'ORF start codon usage:'
enParagraphInfo.tip06 = '"ATG" only'
enParagraphInfo.tip07 = '"ATG" and alternative start codons'
enParagraphInfo.tip08 = 'Any codon that makes sense'
enParagraphInfo.tip09 = 'Retrieved orfs:'
enParagraphInfo.tip10 = 'Select the subset to mark:'
enParagraphInfo.tip11 = 'The six longest orfs'
enParagraphInfo.tip12 = 'Protein sequence FASTA'
enParagraphInfo.tip13 = 'The ORF is displayed as follows:'
enParagraphInfo.tip14 = 'Enter the FASTA format sequence or YYNID number of the nucleotide'
enParagraphInfo.tip15 = 'Tip: Double-click the table data below to mark it'

export const zhOrfFinder = { ...zhBaseInfo, ...zhParagraphInfo }
export const enOrfFinder = { ...enBaseInfo, ...enParagraphInfo }
