import { shallowRef } from "vue";
import { SubLibraryListInter } from "@/components/BaseLayout";
import { i18nUtil } from "@/utils";
import RouteNames from "@/consts/RouteNames";
export default class Service {
  navList = shallowRef<SubLibraryListInter[]>();
  constructor() {}
  // 生成List
  static getImageUrl = (name: string) => {
    const baseUrl = "https://yyhome2022.oss-cn-hangzhou.aliyuncs.com/img/";
    return baseUrl + name;
  };
}

const { getImageUrl } = Service;
// 基因数据
export const geneData = [
  {
    title: i18nUtil.T("nav.batchSearch"),
    img: getImageUrl("01_GBS.svg"),
    router: "BS-Home",
  },
  {
    title: i18nUtil.T("gene.Blast"),
    img: getImageUrl("03_GBL.svg"),
    router: "blastN",
  },
  {
    title: "Primer" + i18nUtil.T("nav.Resource.PrimerDesign"),
    img: getImageUrl("04_GPD.svg"),
    router: RouteNames.Gene.PrimerUploadDetail,
  },
  {
    title: i18nUtil.T("nav.Resource.OpenRead"),
    img: getImageUrl("05_GOF.svg"),
    router: RouteNames.Gene.ORFFinder,
  },
  {
    title: "JBrowse",
    img: getImageUrl("06_GJB.svg"),
    router: RouteNames.Gene.JBrowse,
  },
  {
    title: i18nUtil.T("nav.Protects"),
    img: getImageUrl("07_GRP.svg"),
    router: RouteNames.Gene.nucleobase,
  },
  {
    title: i18nUtil.T("nav.Restriction"),
    img: getImageUrl("08_GRD.svg"),
    router: RouteNames.Gene.ResetMap,
  },
  {
    title: i18nUtil.T("common.DataStatistic"),
    img: getImageUrl("02_GDS.svg"),
    router: RouteNames.Gene.DataStatisticPage,
  },
];
// 化合物
export const compoundData = [
  {
    title: i18nUtil.T("nav.batchSearch"),
    img: getImageUrl("01_CBS.svg"),
    router: "BS-Home",
  },
  {
    title: i18nUtil.T("common.compoundRetrosynthesis"),
    img: getImageUrl("02_CIS.svg"),
    router: RouteNames.Compound.InverseSynthesis,
  },
  {
    title: i18nUtil.T("common.StructureDraw"),
    img: getImageUrl("03_CSD.svg"),
    router: RouteNames.Compound.StructureDrawPage,
  },
  {
    title: i18nUtil.T("common.targetPrediction"),
    img: getImageUrl("04_CTP.svg"),
    router: RouteNames.Compound.TargetPredictionPage,
  },
  {
    title: i18nUtil.T("nav.Resource.SimilarCompound"),
    img: getImageUrl("05_CSC.svg"),
    router: RouteNames.Compound.SimilarCompoundPage,
  },
  {
    title: i18nUtil.T("nav.ADMET"),
    img: getImageUrl("06_CAD.svg"),
    router: RouteNames.Compound.AdmetPrediction,
  },
  {
    title: i18nUtil.T("breadcrumb.PeriodicTable"),
    img: getImageUrl("07_CPT.svg"),
    router: RouteNames.Compound.PeriodicTablePage,
  },
  {
    title: i18nUtil.T("common.DataStatistic"),
    img: getImageUrl("08_CDS.svg"),
    router: RouteNames.Compound.DataStatisticPage,
  },
];
// 天然产物
export const natureData = [
  {
    title: i18nUtil.T("common.BatchUpload"),
    img: getImageUrl("01_NBS.svg"),
    router: "BS-Home",
    span: 5,
  },
  {
    title: i18nUtil.T("common.DataStatistic"),
    img: getImageUrl("02_NDS.svg"),
    router: RouteNames.Nature.DataStatisticPage,
    span: 5,
  },
  {
    title: i18nUtil.T("common.StructureDraw"),
    img: getImageUrl("03_NSD.svg"),
    router: RouteNames.Compound.StructureDrawPage,
    span: 5,
  },
  {
    title: i18nUtil.T("nature.OrganismsTaxonomy"),
    img: getImageUrl("04_NTO.svg"),
    router: RouteNames.Nature.Taxonomy,
    span: 5,
  },
  {
    title: i18nUtil.T("nature.Browser"),
    img: getImageUrl("05_NOD.svg"),
    router: RouteNames.Nature.BrowserPage,
    span: 4,
    query: { page: 1, type: "compound" },
  },
];
// 蛋白质
export const proteinData = [
  {
    title: i18nUtil.T("protein.functionNav.Batch"),
    img: getImageUrl("01_PBS.svg"),
    router: "BS-Home",
  },
  {
    title: i18nUtil.T("protein.functionNav.Blast"),
    img: getImageUrl("02_PBL.svg"),
    router: "blastP",
  },
  {
    title: i18nUtil.T("protein.functionNav.Msa"),
    img: getImageUrl("03_PMA.svg"),
    router: RouteNames.Home.MSA,
  },
  // { title: i18nUtil.T('protein.functionNav.phylogenetictree'), img: getImageUrl('protein/04_PPT.svg'), router: RouteNames.Protein.ClusterAnalysis },
  {
    title: i18nUtil.T("protein.functionNav.AnalysisPhyPro"),
    img: getImageUrl("05_PAP.svg"),
    router: RouteNames.Protein.PhysicochemicalPropertyAnalyze,
  },
  {
    title: i18nUtil.T("protein.virtualScreening"),
    img: getImageUrl("06_PVF.svg"),
    router: RouteNames.Protein.VirtualFiltering,
  },
  {
    title: i18nUtil.T("protein.functionNav.DataStatistic"),
    img: getImageUrl("07_PDS.svg"),
    router: RouteNames.Protein.DataStatisticPage,
  },
];
