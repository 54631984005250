<template>
  <div class="yy-container nav-header">
    <div class="fix-header">
      <!-- {{ computedIsHome }} -->
      <!-- 图片 -->
      <div class="image-box" @click="$router.push({ name: RouteNames.Home.HomePage })">
        <img src="./images/logo.svg" alt="" />
        <img src="./images/title.svg" alt="" />
      </div>
      <!-- 文字信息 -->
      <div class="flex flex-1">
        <div v-for="item in allRouterArr" :key="item.title">
          <el-link :underline="false" @click="$router.push({ name: item.url })" class="cursor-pointer cus-el-link">
            {{ item.title }}
          </el-link>
        </div>
        <!-- 额外的一些参数 -->
        <el-dropdown v-if="$route.path === '/home'">
          <el-link class="cus-el-link" :underline="false">
            {{ $t('nav.serviceLibrary') }}
            <el-icon class="el-icon--right"> <ArrowDown /> </el-icon>
          </el-link>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <a href="https://shop.yyhome2022.com" target="_blank">{{ $t('nav.transaction') }}</a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a href="https://forum.yyhome2022.com" target="_blank">{{ $t('nav.forum') }}</a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!-- tool -->
        <el-link @mouseover="modal = true" :underline="false" class="cursor-pointer cus-el-link">
          {{ i18nUtil.T('homeSearch.Tool') }}
        </el-link>
      </div>
      <!-- AI图片 -->
      <div @click="$router.push({ name: 'chatgpt' })" v-if="computedIsHome" class="ai-image mr-8">
        <img src="https://yyhome2022.oss-cn-hangzhou.aliyuncs.com/img/AI.svg" alt="" />
      </div>
      <in18Btn />
      <!-- 登录信息 -->
      <el-button v-if="!store.userinfo.name" class="ml-8 mr-4" @click="$router.push({ name: 'LoginPage' })">{{ title }}</el-button>

      <div
        v-else-if="store.userinfo.headimage"
        style="display: flex; align-items: center; margin-left: 1.875rem; justify-content: center"
        @mouseover="mouseoverfn"
        @mouseleave="mousemoveFn"
      >
        <div>
          <el-avatar :size="50" :src="store.userinfo.headimage" />
          <div class="quit" v-show="quitShow">
            <div style="margin: 10px 0" @click="PersonalCenter" class="my">
              {{ $t('nav.Mine') }}
            </div>
            <div style="margin: 10px 0" @click="myCollect" class="chu">
              {{ $t('nav.myCollection') }}
            </div>
            <div style="margin: 10px 0" @click="$router.push({ name: RouteNames.Home.Mytask })" class="chu">我的任务</div>
            <div style="margin: 10px 0" @click="quit" class="chu">
              {{ $t('nav.Exit') }}
            </div>
          </div>
        </div>
        <div style="margin-left: 15px">{{ $t('nav.Hello') }} {{ store.userinfo.name }}</div>
      </div>
      <div
        v-else-if="!store.userinfo.headimage"
        style="display: flex; align-items: center; margin-left: 1.875rem; justify-content: center"
        @mouseover="mouseoverfn"
        @mouseleave="mousemoveFn"
      >
        <div style="margin-left: 15px">
          {{ `${$t('nav.Hello')} ${store.userinfo.name}` }}
        </div>
        <div class="quit" v-show="quitShow" style="top: 47px; width: 100px">
          <div style="margin: 10px 0" @click="PersonalCenter" class="my">
            {{ $t('nav.Mine') }}
          </div>
          <div style="margin: 10px 0" @click="myCollect" class="chu">
            {{ $t('nav.myCollection') }}
          </div>
          <div style="margin: 10px 0" @click="$router.push({ name: RouteNames.Home.Mytask })" class="chu">我的任务</div>
          <div style="margin: 10px 0" @click="quit" class="chu">
            {{ $t('nav.Exit') }}
          </div>
        </div>
      </div>
      <!-- 工具面板 -->
      <div class="view-search">
        <PanelList />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { provide, computed, ref } from 'vue'
import { ArrowDown } from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus'
import RouteNames from '@/consts/RouteNames'
import PanelList from './comp/index.vue'
import in18Btn from '@/components/i18Btn/i18Btn.vue'
import { POSTlogout } from '@/api_new/modules/register'
import { i18nUtil, routeUtil } from '@/utils'
import { Service } from './'
import { useStore } from '@/store/store'
import { getCookie } from '@/directives/imgLazy'
const store = useStore()
// 标题
const title = computed(() => (store.userinfo ? i18nUtil.T('common.Login') : i18nUtil.T('common.IsLogin')))

const { allRouterArr, modal, computedIsHome } = new Service()
//控制隐藏
const quitShow = ref(false)
const mouseoverfn = () => {
  quitShow.value = true
}
const mousemoveFn = () => {
  quitShow.value = false
}
//我的-个人中心
const PersonalCenter = () => {
  routeUtil.router.push({ name: RouteNames.Home.personal })
}
//我的收藏
const myCollect = () => {
  routeUtil.router.push({ name: RouteNames.Home.Collect })
}
//退出
const quit = () => {
  ElMessageBox.confirm('退出登录吗', 'Warning', {
    confirmButtonText: 'OK',
    cancelButtonText: 'Cancel',
    type: 'warning',
  })
    .then(async () => {
      try {
        await POSTlogout(getCookie('Authorization'))
        store.userinfo = {}
      } catch (error) {}
    })
    .catch(() => {})
}
provide('modal', modal)
</script>

<script lang="ts">
export default {
  name: 'BaseNavHeader',
}
</script>

<style scoped lang="scss">
.nav-header {
  :deep(.el-link) {
    --el-link-text-color: #333;
  }
  padding-top: 70px !important;
  .fix-header {
    display: flex;
    align-items: center;
    height: 4.5rem;

    .image-box {
      margin-right: 40px;
      display: flex;
    }

    .ai-image {
      width: 30px;
      cursor: pointer;

      img {
        width: 30px;
      }
    }

    .text-info {
    }
  }

  .nav-header--transparent {
    background: transparent !important;
  }

  .view-search {
    position: absolute;
    width: 90%;
    top: 70px;
    right: 100px;
    z-index: 5;
    :deep(.el-card__body) {
      background-color: #f8f8f8;
      opacity: 0.85;
      padding-bottom: 100px;
    }
  }

  .cus-el-link {
    /* color: #333; */
    margin: 0 15px;
    font-size: 16px;
    white-space: nowrap;
  }
}

.quit {
  text-align: center;
  background-color: #fff;
  padding: 10px;
  position: absolute;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 1px 1px 1px #cfcfcf;
  z-index: 99;
}

.my:hover {
  color: #318efe;
}

.chu:hover {
  color: #318efe;
}

.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}

.el-dropdown-link:focus {
  outline: none;
}
</style>
