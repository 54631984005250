import compound_en from './en/compound.en'
import gene_en from './en/gene.en'
import nature_en from './en/nature.en'
import protein_en from './en/protein.en'
import upload_en from './en/upload.en'
import home_en from './en/home.en'
import { enSearch } from './home'
import lang from './view'
import profile from './profile'

import * as other from './other'
export default {
  ...compound_en,
  ...gene_en,
  ...nature_en,
  ...protein_en,
  ...upload_en,
  ...home_en,
  nav: {
    home: 'Home',
    login: 'Login',
    userName: 'userName',
    password: 'password',
    instruction: 'Instruction',
    upload: 'Upload',
    download: 'Download',
    about: 'About',
    transaction: 'Transaction',
    forum: 'Forum',
    batchSearch: 'Batch Search',
    exhibition: 'Exhibition',
    serviceLibrary: 'Service library',
    Home: 'Home',
    HeadTitle: 'Biological Knowledge Base Portal',
    Hello: 'Hello! ',
    Mine: 'Account Setting',
    Exit: 'Exit',
    myCollection: 'My collection',
    more: 'More',
    kindmodel: 'Kind ChatGPT model',
    dataupload: 'Data upload',
    transactionShow: 'Transaction show',
    supermoleculeFitted: 'Supermolecule fitted',
    informationcenter: 'Information center',
    resource: 'Resource',
    database: 'smartbase',
    SyntheticBiology: 'Synthetic Biology',
    knowledgeGraph: 'knowledge graph',
    Protects: 'Restriction enzymes protect the base table', //保护碱
    Restriction: 'Restriction Digestion Point Analysis Tool', //酶切点位
    ADMET: 'ADMET forecast', //ADEMT 预测
    DrugAnalysisTool: 'Drug analysis tool',
    searchRes: 'Search Result',
    AI: 'AI Specialist in life chemistry',
    DownloadData: 'Download data',
    feedback: 'feedback',

    SearchOption: {
      All: 'All',
      Nature: 'Nature',
      Compound: 'Compound',
      Gene: 'Gene',
      Protein: 'Protein',
      NatureCompound: 'All Compounds',
      NatureBioassay: 'Biological Assay',
      NatureFood: 'Food',
      NaturePlant: 'Medicinal Plants',
      NautreCellLine: 'Cell Line',
      GeneBioPro: 'Biological Project',
      GeneBioSam: 'Biological Sample',
      GeneCli: 'Phenotype and Variation',
      GeneNuc: 'Nucleotide',
      CompoundR: 'Chemical Reaction',
    },
    Statisitc: {
      CompoundNum: 'Compounds Number',
      ProteinNum: 'Proteins Number',
      GeneNum: 'Genes Number',
      BioassayNum: 'Bioassays Number',
    },
    HomeCard: {
      Nature: {
        text: 'Natural Product Database',
        classify1: 'Natural Product',
        classify2: 'Bioassay',
        classify3: 'Taxonomy',
      },
      Protein: {
        text: 'Protein Database',
        classify1: 'Protein',
        classify2: 'Target',
        classify3: 'Interaction',
      },
      Compound: {
        text: 'Compound Database',
        classify1: 'Compound',
        classify2: 'Target',
        classify3: 'Chemosynthesis',
      },
      Gene: {
        text: 'Gene Database',
        classify1: 'Gene',
        classify2: 'Nucleotide',
        classify3: 'Phenotype and Variation',
      },
    },
    Resource: {
      Blast: 'BLAST',
      targetPrediction: 'target prediction',
      BlastText: 'Basic Local Comparison Search Tool',
      Msa: 'MSA',
      MsaText: 'Multiple sequence comparison tool',
      PhysicalAndChemicalProperties: 'Prediction of physicochemical property',
      PhysicalAndChemicalPropertiesText: 'Physical and chemical parameter calculation tool',
      SimilarCompound: 'Similar Compound Search',
      SimilarCompoundText: 'Similar Compounds Comparison Tool ',
      JBrowse: 'JBrowse',
      JBrowseText: 'Genome visualization tool',
      Taxonomy: 'Taxonomy',
      TaxonomyText: 'Species classification tool',
      PrimerDesign: ' Design',
      PrimerDesignText: 'PCR template-specific primer design tool',
      ORFfinder: 'ORF finder search',
      ORFfinderText: 'Find DNA sequence open reading frame tool',
      ChemicalReactionPrediction: 'Chemical Reaction Prediction',
      ChemicalReactionPredictionText: 'Sytrain display tool',
      SeeMore: 'More',
      OpenRead: 'Open reading frame search',
    },
    Advertising: {
      Title: 'Based on the platform of life chemistry knowledge base',
      BtnText: 'Immediate Use',
    },
    DataSources: {
      DataSourceDescription: 'Data Source Description',
      NatureAndCompound: 'Natural product database and compounds',
      Protein: 'Protein database',
      Gene: 'Gene database',
      DatabaseName: 'Database Name',
      DataSourceWeb: 'Source Data Website',
      DataSourceLink: 'Source Data Link',
    },
  },
  search: {
    hotKey: 'Hot Keys',
    searchResultPrefix: 'About',
    searchResultSuffix: 'results',
    youCan: 'You can search for the data you want in the ',
    globalSearchPage: 'global search page', //You can search for the data you want in the global search page
    searchYouWant: '',
  },
  download: {
    result: 'Result',
    format: 'Format',
    pic: 'Picture',
    batchDownload: 'Batch Download',
    thisPage: 'Download current page',
    cancel: 'Cancel',
    warn: 'Please first select the content you want to download',
  },
  personal: {
    accountInformation: 'Account information',
    profilePhoto: 'Profile photo',
    modifyProfilePicture: 'Modify profile picture',
    nickname: 'Nickname',
    changeNickname: 'Change a nickname',
    eMail: ' E-mail',
    bindEmailAddress: 'Bind email address',
    cellPhoneNumber: 'Cell-phone number',
    bindMobilePhone: 'Bind mobile phone',
    password: 'Password',
    changePassword: 'Change password',
    note: 'Note: Both English and Chinese, maximum 14 English or 7 Chinese characters',
    enterNewNickname: 'Please enter a new nickname',
    confirm: 'Confirm',
    cancel: 'Cancel',
    enterNewEmailAddress: 'Please enter a new email address',
    enterVerificationCode: 'Please enter a four-digit verification code',
    getCode: 'Get code',
    enterNewPhoneNumber: 'Please enter your new phone number',
    enterOldPassword: 'Please enter the old password',
    enterNewPassword: 'Please enter a new password',
    confirmPassword: 'Please confirm the password',
    yourNickname: 'Your nickname',
    yourEmailAddress: 'Your email address',
    yourPhone: 'Your phone',
    modifySuccessfully: 'Modify successfully',
    acquireSuccess: 'Acquire success',
    bindingSuccessful: 'Binding successful',
    secondPasswordDifferentFromNewPassword: 'The second password is different from the new password',
    WeChat: 'WeChat',
    bindToWechat: 'Bind to wechat',
    organizationInformation: 'Organization information',
    institutionOrganization: 'Institution/organization',
    department: 'department',
    laboratory: 'laboratory',
    post: 'post',
    researchField: 'research field',
    save: 'save',
    personalInformation: 'Personal information',
    familyName: 'Family Name',
    name: 'Name',
    detailedAddress: 'Detailed address',
    postalCode: 'Postal code',
    thirdPartyBinding: 'Third party binding',
    Name: 'Name',
  },
  common: {
    compoundRetrosynthesis: 'compound retrosynthesis',
    LogoutOrNot: 'Logout or not',
    enterUserName: 'Please enter your username',
    FeaturesAreUnderDevelopment: 'Features are under development, so stay tuned',
    myCollect: 'My Collections',
    showAll: 'show all',
    cancelCollection: 'cancel collection',
    SuccessfulCollection: 'Successful collection',
    pleaseLoginIn: 'please login in the web site',
    viewMore: 'view more',
    loading: 'Loading...',
    hotKeys: 'Hot Keys',
    downloadCurrentSearchResult: 'Download Search Result',
    currentSearch: 'Current Search',
    smilesInputTip: 'Please input smiles',
    search: 'Search',
    cancel: 'Cancel',
    confirm: 'Confirm',
    reset: 'Reset',
    browerVersionTooLow: 'The browser version is too low',
    noData: 'There is no data found.',
    goto: 'go to',
    page: 'page',
    bestMatch: 'Best Match',
    all: 'All',
    type: 'Type',
    number: 'Number',
    ComputableFeatures: 'Computable Features',
    value: 'value',
    reference: 'reference',
    source: 'source',
    description: 'description',
    detail: 'detail',
    resultCount: 'results',
    humanDisease: 'human disease',
    nature: 'Nature',
    protein: 'Protein',
    compound: 'Compound',
    gene: 'Gene',
    BatchUpload: 'Batch Search',
    DataStatistic: 'Data Statistic',
    StructureDraw: 'Structure Drawing',
    Login: 'Login',
    IsLogin: 'Logined',
    GoTop: 'Back Top',
    None: 'None',
    targetPrediction: 'Target Prediction',
    NoData: 'No data available',
    ProjectDetail: 'Project Details',
    EditProject: 'Edit Project',
    Menu: 'Menu',
  },
  breadcrumb: {
    Compound: 'Compound Database',
    Gene: 'Gene Database',
    Nature: 'Natural Product Database',
    Protein: 'Protein Database',
    Browser: 'Nature Product - Browser',
    PeriodicTable: 'Periodic Table',
    PhenotypeMutationDetails: 'Phenotype and Mutation Details',
    SimilarCompound: 'Similar Compound Search',
    HomeSimilarCompound: 'Similar Compound Search',
    phylogenetictree: 'phylogenetic tree',

    NatureCompound: 'Compound - Compound',
    NatureCellLine: 'Cell Line',
    NatureFood: 'Food',
    NaturePlant: 'Medical Plant',
    NatureBioassay: 'Bioassay',
    ProteinProtein: 'Protein',
    dataSources: 'dataSources',
    ProteinHumanDis: 'Human Diseases',
    JBrowse: 'JBrowse',
    Upload: 'Upload',
  },
  homedownload: {
    historyDownload: 'Download history version',
    SerialNumber: 'Serial Number',
    name: 'Name',
    OnlineTime: 'Online Time',
    describe: 'Describe',
    DownloadFormat: 'Download Format',
    Selectdownloadformat: 'Select Download Format',
    back: 'Back',
    text1:
      'Description: YYhomeNPs is available to the public as a free resource. The use and redistribution of all or part of the data for commercial purposes requires the express permission of the author and explicit confirmation of the source material (FooDB) and the original publication. We require users who download important parts of the database to identify the source of the information in their published publications. For commercial licenses, please contact us.',
    text2: 'This work is licensed under a Creative Commons Attribution-Non-Commercial Use 4.0 International license.',
  },
  childdownload: {
    Compound: 'Compound',
    Gene: 'Gene',
    Nature: 'Nature',
    Protein: 'Protein',
    downloadPage: 'Download Page',
    latestversion: 'Latest Version',
    theversionofhistory: 'The Version Of History',
  },
  batchUpload: {
    Title: 'Batch Search-',
    NatureDataBase: 'Natural Product Database',
    CompoundDataBase: 'Compound Database',
    ProteinDataBase: 'Protein Database',
    GeneDataBase: 'Gene Database',
    Nature: 'Nature',
    Compound: 'Compound',
    Protein: 'Protein',
    Gene: 'Gene',
    OneFuncTitle: 'Method 1: Select the identifier type and enter the identifier separated by a semicolon, such as 1436;1427;1428',
    inputTitle: 'Please input identifier...',
    SelectTitle: 'Please select identifier type',
    AddBtn: 'Add more identifier types',
    DeleteBtn: 'Delete the filter',
    TwoFuncTitle: 'Method 2: Upload the identifier list file',
    SearchBtn: 'Search',
    UploadBtn: 'Click to upload',
    DownloadBtn: 'Download template',
    UnuploadedText: 'Upload file for preview, not uploaded yet',
    UploadFailedText: 'Upload failed: Please check the file format',
    FileTooLarge: 'File size cannot exceed 5MB!',
    Preview: 'Preview',
    FileName: 'filename',
    SerialNum: 'Serial number',
    IdentifierType: 'Identifier type',
    Identifier: 'Identifier',
    LineNum: 'Line number',
    Rows: 'rows',
    WarningStr: 'Please input search criteria!',
    WarningFile: 'Please upload files for search!',
    UploadTip: 'Upload the xlsx/xls/csv file with a maximum size of 5MB and only one file.',
    MaxAdd: 'A maximum of ',
    Piece: ' piece of data can be added',
    MinDel: 'The first filter could not be deleted',
    Options: {
      Nature: {
        lable1: 'Search by Bioassay id-YYAid,such as 83187',
        lable2: 'Search by Medicinal plant id-YYPid,such as 14',
        lable3: 'Search by Food plant id-YYFid,such as 21',
        lable4: 'Search by Compound id-YYNPsid,such as 83187',
        lable5: 'Search by Smiles,such as C1=CC=CC=C1',
        lable6: 'Search by Cas,such as 25053-22-9',
        lable7: 'Search by Simple structural formula,such as C6H6',
        lable8: 'Search by InchI,such as InChI=1S/C9H8O4/c1-6(10)13-8-5-3-2-4-7(8)9(11)12/h2-5H,1H3,(H,11,12)',
        lable9: 'Search by Species Latin name,such as Daucus carota',
        type1: 'Bioassay id',
        type2: 'Medicinal plant id',
        type3: 'Food plant id',
        type4: 'Compound id',
        type5: 'Smiles',
        type6: 'Cas',
        type7: 'Simple structural formula',
        type8: 'InchI',
        type9: 'Species Latin name',
      },
      Compound: {
        lable1: 'Search by Compound id-YYCID,such as 1476',
        lable2: 'Search by Smiles,such as C1=CC=CC=C1',
        lable3: 'Search by Cas,such as 50-78-2',
        lable4: 'Search by Simple structural formula,such as C6H6',
        lable5: 'Search by InchI,such as InChI=1S/C9H8O4/c1-6(10)13-8-5-3-2-4-7(8)9(11)12/h2-5H,1H3,(H,11,12)',
        type1: 'Compound id',
        type2: 'Smiles',
        type3: 'Cas',
        type4: 'Simple structural formula',
        type5: 'InchI',
      },
      Protein: {
        lable1: 'Search by Protein id-yyhomePID,such as 1476',
        lable2: 'Search by Protein Name,such as TP53-binding protein 1',
        lable3: 'Search by Category,such as Homo sapiens',
        lable4: 'Search by Amino acid sequence length,such as 214',
        lable5: 'Search by uniprotID,such as A0A0C5B5G6',
        type1: 'Protein id',
        type2: 'Protein Name',
        type3: 'Category',
        type4: 'Amino acid sequence length',
        type5: 'uniprotID',
      },
      Gene: {
        lable1: 'Gene Name,such as TP53 - tumor protein p53',
        lable2: 'Gene Id - YYGN id,such as YYGN27299',
        lable3: 'Nucleotide - YYNC id,such as YYNC1000365143',
        lable4: 'Biological project - YYPJ id,such as YYPJ132541',
        lable5: 'Biological sample - YYBSid,such as YYBS10130874',
        lable6: 'Phenotypes AND VARIANTS - YYCL id,such as 2368866',
        lable7: 'Species name (species),such as Homo sapien',
        type1: 'Gene Name',
        type2: 'Gene Id',
        type3: 'Nucleotide',
        type4: 'Biological project',
        type5: 'Biological sample',
        type6: 'Phenotypes AND VARIANTS',
        type7: 'Species name (species)',
      },
    },
  },
  SimilarCompound: {
    DataBase: 'Database：',
    BackDraw: 'Return to data upload',
    Title: 'Similar Compound Search',
    Draw: 'Structure Drawing',
    Result: 'Search Results',
    InputText: 'Please input Smiles...',
    Index: 'Serial number',
    Structure: 'Structure',
    Name: 'Name',
    SimpleStructuralFormula: 'Simple structural formula',
    Similarity: 'Similarity',
    Waring: 'Data not available',
    Sort: {
      Sort: 'Sort',
      ASC: 'Ascending Sort',
      DESC: 'Descending Sort',
    },
    Filter: {
      Filter: 'Filter',
      Similar5: 'Similarity ≥ 0.5',
      Similar8: 'Similarity ≥ 0.8',
    },
    ConfirmBtn: 'Confirm',
    ResetBtn: 'Reset',
  },
  Footer: {
    Connection: 'Connection: yyhome',
    Email: 'Email: ',
    Telephone: 'Phone: 151 6859 8681',
    Website: 'Website: ',
    Address: 'Address: ',
    DetailAddress: '浙江省宁波市镇海区启迪科技园A座1101室',
    Statement: 'License and free statement',
    DataSource: 'Data Source',
    Record: 'Record Number: 浙ICP备2021039509号',
    License: {
      License: 'License: ',
      LicenseText: 'We have chosen to apply the Creative Commons Attribution 4.0 International (CC BY4.0) license to all copyrighted portions of our database.',
      Disclaimer: 'Disclaimer: ',
      DisclaimerText:
        'We do not guarantee the accuracy of the data, nor are we liable for any damage caused by the use of the data. We are unable to provide unrestricted permission for the use of data, as some data may be protected by patents or other rights. Any medical or genetic information is used only for research, education and information purposes. It is not used in any way as a substitute for professional medical advice, diagnosis, treatment or care.',
    },
  },
  blast: {
    SubmitTask: 'Submit Task',
    myTask: 'My Task',
    MyResults: 'My Results',
    inquiry: 'Inquiry',
    inputFASTA: 'Input FASTA sequence',
    example: 'Example',
    clear: 'Clear',
    placeholder1: 'Please enter the FASTA sequence',
    uploadFile: 'Upload Files',
    tips1: 'Please upload the local FASTA file',
    goal: 'Goal',
    radio1: 'Compare sequences in a database',
    radio2: 'Compare the input sequence',
    database: 'Data Base',
    NucleotideSet: 'Nucleotide Set',
    genome: 'Genome',
    transcriptome: 'Transcriptome',
    programselection: 'Program Selection',
    programoptimization: 'Program Optimization',
    setTaskName: 'Set Task Name',
    viaemail: 'Notification via Email',
    settingparameter: 'Setting Parameter',
    outputformat: 'Output Format',
    generalparameter: 'General Parameter',
    text1: 'The maximum number of aligned sequences to be preserved',
    text2: 'Expected value setting',
    text3: 'Word length ( matching fragment length )',
    default: ' default is ',
    Scoringparameters: 'Scoring Parameters',
    filter: 'Filtering and shielding programs',
    submit: 'Submit',
    errorText1: 'Please upload your serial number (choose one of two on the file and serial number)',
    successText1: 'Successful upload Please wait a moment',
    errorText2: '( One out of two )',
    errorText3: 'Upload file cannot be empty (please select upload file form)',
    errorText4: 'Please submit Files',
    successText2: 'Upload successful please wait to check',
    errorText5: 'Serial number and contrast serial number cannot be empty (please select upload method)',
    errorText6: 'Upload serial number or file cannot be empty (please select upload method)',
    errorText7: 'Please submit documents',
    errorText8: 'Upload file cannot be empty (please select upload method)',
    errorText9: 'The upload sequence number cannot be empty',
    errorText10: 'Must be a fasta file',
    errorText11: 'The uploaded file cannot be larger than 500MB',
    errorText12: 'The uploaded file cannot be empty',
    text4: 'Sequences that produce significant contrast',
    download: 'Download',
    selectcol: 'Selection Column',
    show: 'Show',
    sequencenumber: 'Sequence Number',
    Maximumscore: 'Maximum Score',
    TotalPoints: 'Total Points',
    TargetSequenceCoverage: 'Target Sequence Coverage',
    ConsistentPercentage: 'Consistent Percentage',
    sequencelength: 'Sequence Length',
    sequence: 'Sequence',
    nucleotide: 'Nucleotide',
    protein: 'Protein',
    Nucleotidequality: 'Nucleotide Quality',
    TaskName: 'Task Name',
    TaskID: 'Task ID',
    cdownload: 'Click to download',
    UploadTime: 'Upload Time',
    WorkingCondition: 'Working Condition',
    complete: 'Complete',
    Return: 'Return to submit task',
    procedure: 'Procedure',
    quote: 'Quote',
    moleculartype: 'Molecular Type',
    querylength: 'Query Length',
    aminoacid: 'Amino Acid',
    run: 'running',
    tishi1: 'Your blast job has been submitted',
    tishi2: "You can wait here until it's finished",
    tishi3: 'This status will be checked once a second',
    tishi4: 'Note: If the file is too large, you can copy the job ID and retrieve the results later in the My BLAST task page.',
    tishi5: 'Please upload your serial number or select from My Tasks.',
    myBlastTask: 'My BLAST Task',
    BLASTres: 'BLAST Results',
    errorText13: 'No result, please upload the result or view my task',
    text5: 'Find Your BLAST Task ( Your BLAST results are kept for up to one week )',
    text6: 'Task ID or notification email',
    append: 'Append',
    Submitin: 'Submit In',
    state: 'State',
    Maturitytime: 'Maturity Time',
    operation: 'Operation',
    confirmText1: 'Are you sure to delete this result?',
    errorText14: 'Serial number error',
  },
  primer: {
    primerdesign: 'Primer Design',
    PCR: 'PCR Template',
    text1: 'Please enter the FASTA sequence, or upload a file',
    uploadFile: 'Upload File',
    NOselect: 'No file is selected',
    placeholder1: 'Enter or paste the PCR template here with a maximum length limit of 50,000 bps...',
    text2: 'No upload or input sequence yet',
    text3: 'Not fill in the PCR length limit can not be entered',
    PCRname: 'PCR template name',
    placrholder2: 'Please enter the PCR name...',
    Primerparameters: 'Primer Parameters',
    NumberofPCRproducts: 'Number of PCR products',
    PCRproductlength: 'PCR Product Length',
    Primerlength: 'Primer Length',
    PrimerTM: 'Primer unwinding temperature ( Tm )',
    run: 'Run',
    reset: 'Reset',
    error1: 'Please enter the fasta sequence',
    error2: 'Please enter the correct fasta sequence.',
    error3: 'Note that this sequence must start with > start',
    error4: 'Please note that the sequence can contain only one newline character!',
    error5: 'The current input sequence length exceeds the defined PCR product length',
    error6: 'The current input sequence length is less than the defined PCR product length',
    error7: 'Upload file and fasta sequence can only choose one of them.',
    error8: 'The file must end in .fasta format',
    title1: 'Primer parameter upload',
    title2: 'Primer design details',
    required: 'Required',
    des: 'No data is currently being requested!',
    PCRinfo: 'PCR Template Information',
    PCRrange: 'PCR template range',
    Primerpairresultview: 'Primer Pair Result View',
    detailedresults: 'Detailed Results',
    Primerpairs: 'Primer Pairs',
    sequence: 'Sequence',
    templatecolumn: 'Template Chain',
    length: 'length',
    initiation: 'Initiation',
    termination: 'Termination',
    Selfcomplementarity: 'Self-complementarity',
    isselfcomplementary: "The 3 ' end is self-complementary",
    forwardprimer: 'Forward Primer',
    reverseprimer: 'Reverse Primer',
    sizeoftheproducts: 'Size Of The Products',
  },
  aboutus: {
    ContactUs: 'Contact Us',
    aboutNature: 'About Natural Products',
    missions: 'Missions',
    cooperation: 'co-operation',
    Ourinfluence: 'Our Influence',
    naturelib: ' natural product library',
    text1:
      "Established in 2022, the yyhome Natural Products Library is the world 's largest free-accessible collection of chemical information. Search for chemicals by name, formula, structure, and other identifiers for chemical and physical properties, biological activity, safety and toxicity information, patents, references, and more.",
    Ourmission: 'Our Mission',
    text2:
      'We are part of the yyHome Strategic Data Platform, a 50-person R & D team exclusively funded, operated and maintained by Ningbo Yongheng Yaoyao Intelligent Technology Co., Ltd. The center is mainly devoted to the following three aspects : focusing on the precision medicine related omics information resources of the Chinese population for inclusive health, improving the establishment of the Chinese population genomic genetic variation map, and forming the Chinese population precision medicine information database ; based on the massive original omics data resources of high-throughput sequencing, an original omics data archive library in line with international standards was established to form a sharing platform for Chinese original omics data. Focusing on the national important strategic biological resources, a system of integration, mining and application of massive omics data is established to form a comprehensive multi-omics database system.',
    annualreport: 'Annual Report',
    text3: 'Key Statistics and Highlights of 2021',
    text4: 'We work with scientists and engineers around the world and provide the infrastructure needed to openly share data in the life sciences.',
    text5: 'Understanding how genetics affects the health of humans, plants, and animals is critical to disease prevention, food security, and advances in biodiversity.',
    text6:
      'We develop databases, tools, and software to make it possible to align, validate, and visualize the various data generated in publicly funded research, and to make this information freely available to all.',
    text7: 'The average daily network request to our website',
    text8: 'Visit Us',
    text9: 'Collaborate with academic institutions across 52 countries / regions',
    text10: 'Number of original data storage',
    text11:
      'Explore our top stories for 2021, including the launch of the AlphaFold database and the EMBL- EBL data resources and research on how to support the COVID-19 pandemic response',
    text12: 'Main database cooperation',
    text13: 'Our core database is co-created with other world leaders, including',
    litext1: 'Academic institution cooperation',
    litext2: 'Enterprise cooperation',
    litext3: 'Database cooperation',
    aboutProtein: 'About Protein',
    outline: 'Outline',
    text14: 'Who are we',
    text15:
      "At yyhome 's protein knowledge base, we help scientists realize the potential of big data in protein research, using complex information to make discoveries that are beneficial to humans.",
    text16: 'The yyhome protein knowledge base is an international, innovative, interdisciplinary, and open data advocate in the field of life sciences.',
    text17: "We are part of yyHome 's large yyhome Strategic Data Platform, a 50-person R & D team",
    text18:
      "It is exclusively funded, operated and maintained by Ningbo Yongheng Yaoyao Intelligent Technology Co., Ltd. We are located in the People 's Republic of China, Ningbo City, Zhejiang Province, Zhongguan West Road, No. 1277 Qikai Science and Technology Park.",
    text19:
      'We are a highly collaborative organization that collaborates with organizations around the world to provide data services, international research projects of global significance, and joint programs for companies of all sizes.',
    // text20:
    //   'We are also a partner of several emerging research infrastructures in Europe and host the ',
    center: ' center',
    // text21:
    //   'This is the distributed infrastructure of life science information in Europe.',
    // text22:
    //   'We are the European node for global coordinated data collection and dissemination projects such as the Global Protein Database ( wwPDB ) and the International Nucleotide Sequence Database Collaboration ( INDSC ).',
    aboutGene: 'About Gene',
    genedatabase: 'Gene Database',
    text23:
      'The Gene Database Center was established in 2022. Aiming at the actual needs of " storage, management and use " of genomic data in China and the major problems of " data island " and " data sovereignty, " the center focuses on the construction of database system and data resources, data service, system operation and maintenance, technology research and development, data mining and other series of work around human, animal, plant, microorganism and other genomic data, and builds an internationally influential genomic science data center to promote the open sharing of scientific data, ensure the safety and controllability of scientific data, and support national scientific and technological innovation and economic and social development.',
    aboutCompound: 'About Compound',
    overview: 'Overview',
    databaseService: 'Database Service',
    databaseAdvantages: 'Database Advantages',
    databaseCollaboration: 'Database Collaboration',
    compounddatabase: 'Compound Database',
    text24:
      'YYhomeC compound database was established in 2022. In order to solve the defects of small amount of data, less content and poor maintenance in the existing chemical professional database in China, and to eliminate the risks of data outage of foreign compound database, domestic technology and data loss, the construction of compound data resource integration and sharing platform is focused on.',
    text25:
      "YYhomeC Compounds Database is the world 's largest free-accessible collection of chemical information. Chemicals can be searched by name, molecular formula, structure and other identifiers to find chemical and physical properties, biological activity, safety and toxicity information, patents, literature citations, etc. It has similar compound search and target prediction functions ; at the same time, it contains a chemical reaction sub-library to provide a chemical reaction route.",
    Service1: 'One-dimensional Chinese name / SMILES / InChI form query',
    Service2: 'Provide 2D chemical structure query',
    Service3: '2D chemical structure formula drawing tool facilitates molecular query',
    Service4: 'Provide 3D chemical structure visualization display',
    Service5: 'AI compound skeleton recognition algorithm',
    Service6: 'Search for similar compounds',
    Service7: 'Compound target prediction',
    Advantages1: 'Open source data, free download, strengthen academic sharing',
    Advantages2: 'Diversified database functions, data visualization',
    Advantages3: 'Including Chinese and English multilingual data sources, data integrity, avoid redundancy.',
    Advantages4: 'Content Chinese, easy to read, improve work efficiency',
    Advantages5: 'Breaking the monopoly of foreign biochemical data and solving the risk of data disconnection',
  },
  JBrowse: {
    HeadTitle: 'Genome Maps',
    HeadText:
      'Gene map is an updatable transcriptome resource. The database contains information about related genomes, including sequences, maps, chromosomes, assembly and annotation. In order to study the detailed information of different genomes, the gene map provides insights into the changes in the transcriptional landscape and accurately annotates genetic elements.',
    DownloadBtn: 'Download',
    Details: {
      PublicationDate: 'Release Date:',
      BioProject: 'Biological Projects:',
      Overview: 'Summary',
      GenomicInformation: 'Genomic Information',
    },
  },
  News: {
    LatestNews: 'Latest News',
    List: {
      Nature: 'Natural Products',
      Protein: 'Protein',
      Compound: 'Compound',
      Gene: 'Gene Database',
    },
    Years: 'Years',
    Day: 'th',
    MonthList: {
      January: 'Jan',
      February: 'Feb',
      March: 'Mar',
      April: 'Apr',
      May: 'May',
      June: 'Jun',
      July: 'Jul',
      August: 'Aug',
      September: 'Sept',
      October: 'Oct',
      November: 'Nov',
      December: 'Dec',
    },
    Select: 'Search Information',
    InputText: 'Keyword',
    SelectBtnText: 'Search',
    HotTags: 'Hot Tags',
    DetailsBtn: 'View details',
    Details: {
      NewHome: 'News Center',
      NewDetail: 'News Detail',
      Source: 'Source: ',
      PreviousPage: 'Previous Page: ',
      NextChapter: 'Next Chapter: ',
      Home: 'Home',
      RecommendedReading: 'Recommended Reading',
    },
  },
  login: {
    login1: 'Log in to YYHOME',
    login2: 'You will be able',
    text1: 'Upload sequences - Contribute to the open source world',
    text2: 'Participate in forums - Discuss academic issues',
    text3: 'Purchasing equipment - Instrument reagent service',
    wechat: 'Wechat',
    Account: 'Account',
    phone: 'Phone',
    p1: 'Email or mobile phone',
    pwd: 'password',
    code: 'code',
    text4: "Can't see? Please refresh the code",
    noact: 'No account?',
    creatact: 'Create an account',
    p2: 'Please enter phone',
    text5: 'Get free code ',
    setName: 'Set Name',
    verify: 'Verify',
    set: 'Please set the name',
    err1: 'Please enter your email or mobile number',
    err2: 'Please enter password',
    err3: 'Please enter a nickname',
    err4: 'Please enter the phone',
    err5: 'Please enter the mobile verification code',
    err6: 'Please enter the verification code',
    err7: 'The verification code is invalid. Refresh and log in again',
  },
  register: {
    title: 'Welcome to register YYHOME account',
    EmailRegistration: 'Email Registration',
    form1: 'Account Login Information',
    email: 'Email',
    p1: 'Please enter your email address',
    pwd: 'password',
    p2: 'Please enter password',
    ConfirmPassword: 'Confirm Password',
    PersonalInformation: 'Personal Information',
    surname: 'Surname',
    name: 'name',
    address: 'Address',
    address1: 'Detailed address',
    Postalcode: 'Postal Code',
    Organizationinformation: 'Organization Information',
    l1: 'Institution/organization',
    section: 'Section',
    laboratory: 'laboratory',
    job: 'Job',
    Researchfield: 'Research field',
    code: 'Code',
    codetext: "Can't see? Please refresh the two verification code",
    submit: 'Submit',
    back: 'Back',
    err1: 'Please enter your password again',
    err2: 'The two passwords are inconsistent',
    err3: 'Please enter your email address',
    err4: 'Please enter last name',
    err5: 'Please enter your name',
    err6: 'Mailbox format error!',
    err7: 'The mailbox cannot be empty',
    err8: 'The address cannot be empty. Please select an address',
    err9: 'Please enter your full address',
    err10: 'Please enter password',
    err11: 'Please enter 8-30 characters of English letters, numbers or symbols (excluding Spaces) with at least two types of letters, numbers and punctuation marks.',
    err12: 'Please enter the correct email',
    Mailboxverification: 'Mailbox verification',
    Resend: 'Resend the verification code',
    Registeredsuccessfully: 'Registered successfully',
    Verificationcodeerror: 'Verification code error',
  },
  homeSearch: {
    ...enSearch,
  },
  homeAboutUs: {
    text1:
      'YLCIK Life Chemistry Intelligence Database portal is A portal website of life chemistry information database developed by Yongheng Yaoyao Science and Technology Co., LTD., adhering to the public welfare line of full open source and free, the project has completed the construction of official version A so far, and a total of compounds are included ',
    numbers: ' counts',
    protein: 'protein ',
    gene: 'gene ',
    bioassay: 'and bioassay ',
    text2:
      'We hope to establish an international, innovative and interdisciplinary knowledge sharing and exchange platform for life chemistry, and we are the champion of open data in the field of life sciences.',
    text3:
      'Yongheng Yaoyao Technology Co., Ltd. is a company formed by a number of senior scientists, professors and professionals from Zhejiang University, Trinity College of Ireland, University of Florida, etc., committed to combining cutting-edge technologies in the fields of science and technology, innovation, biology, artificial intelligence and big data to provide customers with quality products and services. The focus is on life chemistry data, innovation, equipment and other businesses. At present, the project has joined the "Photosynthesis Organization", intends to register the project in the Chinese Society of Biological Engineering, and has reached cooperation with the Wuzhen National Computing Center of Dawning.',
    title1: 'Research Programme',
    title1p:
      'We contribute to the advancement of life sciences and chemistry through computational research. In the age of genomics, our computational research is increasingly transformational and relevant to human health, disease and the environment',
    teach: 'Train',
    teachText1: 'We provide advanced training in biological and chemical informatics for scientists and industry workers at all levels.',
    teachText2: 'Our bioinformatics hands-on training helps experimental and computational biologists make the most of our extensive data and algorithmic resources',
    data: 'Data Resource',
    cooperation: 'Trade Cooperation',
    Info: 'Consulting and trading',
    InfoP: 'We provide information service, technology exchange, technology trading, product trading platform.',
    time1: '2023.06',
    time1Title: 'YLCIK Life Intelligence Tank version A1 online',
    time1Text1: 'It contains multiple primary sublibraries of compounds, natural products, proteins, genes, biological taxonomy, bioassays, etc.',
    time1Text2:
      'It contains dozens of secondary sub-libraries, including plants, food, phenotype and variation, and chemical synthesis, with an average content of more than 80 items per sub-library.',
    time1Text3: 'The amount of open source data collection has reached 60% of similar foreign countries;',
    time1Text4: 'More than 15 algorithm functions;',
    time2: '2023.01',
    time2Title: 'YLCIK Life Intelligence Database demo version online',
    time2Text1: 'It contains four sub-libraries of compounds, natural products, proteins and genes.',
    time2Text2: 'Front and rear end frames complete, with internal debug pages;',
    time2Text3: 'The functionally similar compounds of the algorithm were developed;',
    time3: '2023.12',
    time3Title: 'YLCIK Life Intelligence Database A version is officially launched',
    time3Text1:
      'On the basis of A1 version, GPT generative life chemistry Assistant is added, which can serve to write papers, design experiments, and understand industry dynamics',
    time3Text2: 'Increased the multi-language translation function in the field of life chemistry, more accurate than Google Medical Translation, and added 2 NLP sublibraries;',
    time3Text3: 'Service platform officially launched, including forum and trading functions;',
    teams:
      'The project team is composed of key members of "Zhejiang Provincial Key Science and Technology Innovation Team (approved by Zhejiang Provincial Party Committee and Provincial Government in 2011)". Supported by the team in the form of independent entrepreneurship, the team is composed of highly educated young scientific and technological personnel, with a number of independent intellectual property rights, a number of independent technical algorithms, a number of independent data sets, a number of international cutting-edge technology research results, with a strong technical foundation and innovation ability. The team pursues to solve the problem of industry bottlenecks to achieve foreign replacement of domestic innovation, and strives to become a pioneer enterprise in the domestic bioinformatization industry, a national standard standard maker, and a leading national bioinformatization platform and an innovative research and development base platform for new drug discovery.',
  },
  // 历史记录
  History: { ...lang.enHistory },
  // 个人中心
  Profile: { ...profile.enPersonal },
  // 批量查找
  BS: { ...other.enBatchSearch },
  feedback: {
    btn1: "Give Advice",
    btn2: "Find BUG",
    btn3: "Show Gratitude",
    desc: "Description:",
    upload: "Upload screenshot:",
    email: "Email Adress:",
    submit: "submit",
    tip1: "Do you have any suggestions for the whole site?",
    tip2: "For the whole website, if you find some defects, please let us know in time so that we can deal with it in a timely manner.",
    tip3: "Whether this website can help you, if it brings you portability and help, we will be very honored.",
    tip4: "Please describe your problem in less than 1,000 words.",
    tip5: "Please upload the screenshot of the problem in order to solve the problem in time. At present, only png and jpg images are allowed, and a maximum of 9 images can be uploaded.",
    tip6: "If you would like to receive our reply, please enter your email address.",
    limit: "Warning: Upload a maximum of 9 images",
    submit1: "Your feedback has been submitted successfully, thank you for your attention",
  }
}
