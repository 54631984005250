import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  Router,
  RouteRecordRaw,
  useRouter,
} from "vue-router";
import { NatureRoutes } from "./nature";
import { ProteinRoutes } from "./protein";
import { GeneRoutes } from "./gene";
import { CompoundRoutes } from "./compound";
import { uploadItem } from "./modules";
import { News } from "./news";
import TestRoutes from "./test";
import RouteNames from "@/consts/RouteNames";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import { GETwechatcheck, GETuserinfo } from "@/api_new/modules/register";
import { useStore } from "@/store/store";
import { ElMessage } from "element-plus";
import { getCookie, delCookie } from "@/directives/imgLazy";
import { POSTwechatBinding } from "@/api_new/modules/user";

function noLogin(to: any, from: any) {
  if (getCookie("Authorization")) {
    return true;
  } else {
    ElMessage({
      message: "请先登录！",
      type: "warning",
    });
    return;
  }
}
import Other from "./GY/others";
const routes: RouteRecordRaw[] = [
  {
    path: "/login",
    name: RouteNames.Login.HomePage,
    component: () => import("@/views/Login/LoginPage.vue"),
  },
  {
    path: "/home",
    name: RouteNames.Home.HomePage,
    component: () => import("@/views/Home/Home.vue"),
  },
  // 展示界面
  {
    path: "/displayPage",
    name: RouteNames.Home.DisplayPage,
    component: () => import("@/views/Home/display-page/index.vue"),
  },
  {
    path: "/allSearch",
    name: RouteNames.Home.HomeAllSearch,
    component: () => import("@/views/Home/all-search/index.vue"),
  },
  {
    path: "/dataSources",
    name: RouteNames.Home.dataSources, //数据来源页面
    component: () => import("@/views/Home/dataSources/dataSources.vue"),
  },
  {
    path: "/instruction",
    name: RouteNames.Home.instruction,
    component: () => import("@/views/Home/instruction/index.vue"),
  },
  {
    path: "/registration",
    name: RouteNames.Home.registration,
    component: () => import("@/views/Home/registration/registration.vue"),
  },

  {
    path: "/MSA",
    name: RouteNames.Home.MSA,
    component: () => import("@/views/Home/MSA/msaSubmit.vue"),
  },
  {
    path: "/MSAMyAssignment",
    name: RouteNames.Home.MSAMyAssignment,
    component: () => import("@/views/Home/MSA/myAssignment.vue"),
  },
  {
    path: "/MSAResult/:tasksId?",
    name: RouteNames.Home.MSAResult,
    component: () => import("@/views/Home/MSAResult/index.vue"),
  },
  //关于我们页面
  {
    path: "/AboutUs",
    name: RouteNames.Home.AboutUs,
    component: () => import("@/views/Home/AboutUs/aboutUs.vue"),
  },
  {
    path: "/personal",
    name: RouteNames.Home.personal,
    component: () => import("@/views/Home/personal/personal.vue"),
  },
  {
    path: "/collect",
    name: RouteNames.Home.Collect,
    component: () => import("@/views/Home/Collect/collect.vue"),
  },
  {
    path: "/knowledgeAtlas", //知识图谱
    name: RouteNames.Home.knowledgeAtlas,
    component: () => import("@/views/Home/knowledgeAtlas/knowledgeAtlas.vue"),
  },
  {
    path: "/",
    redirect: "/displayPage",
  },
  {
    path: "/404",
    name: RouteNames.Generic.NotFoundPage,
    component: () => import("@/views/404/NotFoundPage.vue"),
  },
  {
    path: "/:catchAll(.*)", // 不识别的path自动匹配404
    redirect: "/404",
  },
  {
    path: "/homeDownload",
    name: RouteNames.Home.HomeDownload,
    component: () => import("@/views/Home/HomeDownload/HomeDownloadTest.vue"),
  },
  {
    path: "/ChildDownload",
    name: RouteNames.Home.ChildDownload,
    component: () => import("@/views/Home/ChildDownload/ChildDownload.vue"),
    redirect: { name: RouteNames.Home.NatureDownload },
    children: [
      {
        path: "NatureDownload",
        name: RouteNames.Home.NatureDownload,
        component: () =>
          import("@/views/Home/ChildDownload/components/DownLoad.vue"),
      },
      {
        path: "ProteinDownload",
        name: RouteNames.Home.ProteinDownload,
        component: () =>
          import("@/views/Home/ChildDownload/components/DownLoad.vue"),
      },
      {
        path: "CompoundDownload",
        name: RouteNames.Home.CompoundDownload,
        component: () =>
          import("@/views/Home/ChildDownload/components/DownLoad.vue"),
      },
      {
        path: "GeneDownload",
        name: RouteNames.Home.GeneDownload,
        component: () =>
          import("@/views/Home/ChildDownload/components/DownLoad.vue"),
      },
    ],
  },
  {
    path: "/blastTaskbar",
    name: RouteNames.Protein.blastTaskbar,
    component: () => import("@/views/Home/Blast/taskbar/index.vue"),
  },
  {
    path: "/blastVisual",
    name: RouteNames.Protein.Visual,
    component: () => import("@/views/Home/Blast/Visual/index.vue"),
  },
  {
    path: "/blast",
    name: RouteNames.Protein.BlastPage,
    component: () => import("@/views/Home/Blast/index.vue"),
    children: [
      {
        path: ":echoiD?",
        name: "blastN",
        component: () => import("@/views/Home/Blast/blastN/index.vue"),
      },
      {
        path: "blastP/:echoiD?",
        name: "blastP",
        component: () => import("@/views/Home/Blast/blastP/index.vue"),
      },
      {
        path: "blastX/:echoiD?",
        name: "blastX",
        component: () => import("@/views/Home/Blast/blastX/index.vue"),
      },
      {
        path: "TblastN/:echoiD?",
        name: "TblastN",
        component: () => import("@/views/Home/Blast/TblastN/index.vue"),
      },
    ],
  },
  {
    path: "/chatgpt",
    name: RouteNames.Home.chatgpt,
    beforeEnter: (to, from, next) => {
      if (getCookie("Authorization")) return next();
      ElMessage({ message: "请先登录！", type: "warning", grouping: true });
      next(from);
    },
    component: () => import("@/views/Home/chatgpt/index.vue"),
  },
  {
    path: "/Mytask", //我的任务
    name: RouteNames.Home.Mytask,
    component: () => import("@/views/Home/MyTask/index.vue"),
  },
  {
    path: "/Show",
    name: RouteNames.Show.Show,
    component: () => import("@/views/Show/index.vue"),
  },
  {
    path: "/SearchTotal",
    name: RouteNames.Home.SearchAll,
    component: () => import("@/views/Home/SearchAll/index.vue"),
  },
  {
    path: "/feedback",
    name: RouteNames.Home.feedback,
    component: () => import("@/views/Home/feedback/index.vue"),
  },
  ...uploadItem,
  ...NatureRoutes,
  ...ProteinRoutes,
  ...GeneRoutes,
  ...CompoundRoutes,
  ...News,
  ...uploadItem,
  ...Other,
];

let router: Router;

if (import.meta.env.DEV || import.meta.env.MODE == "dev") {
  router = createRouter({
    history: createWebHistory(),
    routes: routes.concat(TestRoutes),
  });
} else {
  router = createRouter({
    history: createWebHashHistory(),
    routes,
  });
}
router = createRouter({
  history: createWebHistory(),
  routes: routes.concat(TestRoutes),
});
// 路由全局前置守卫
router.beforeEach(async (to: any, from, next) => {
  router.onError((error, to) => {
    const errors = [
      "Failed to fetch dynamically imported module",
      "Unable to preload CSS",
    ];

    if (errors.some((e) => error.message.includes(e))) {
      window.location = to.fullPath;
    }
  });
  const store = useStore();
  if (to.query.code && to.query.state && to.name !== "personal") {
    try {
      // 判断进入页面的时候有没有code的参数因为在微信扫码的时候地址栏中会有code，跳转地址是上一次进来的入口
      const { data } = await GETwechatcheck(
        to.query.code,
        String(to.query.state)
      );
      store.userinfo = data;
      next({ path: from.fullPath });
      ElMessage({
        message: "登录成功",
        type: "success",
      });
    } catch (error: any) {
      ElMessage.error("验证失败,请重新扫描二维码");
    }
  }
  if (to.name === "personal" && to.query.code && to.query.state) {
    try {
      let formData = new FormData();
      formData.append("state", to.query.state);
      formData.append("code", to.query.code);
      const { data, msg, code } = await POSTwechatBinding(formData);
      router.push({ name: RouteNames.Home.personal });
    } catch (error: any) {
      ElMessage.error("该微信号已注册，使用微信登录以绑定其他信息");
      router.push({ name: RouteNames.Home.personal });
    }
  }

  //判断是否cookie进行请求用户信息
  if (getCookie("Authorization")) {
    try {
      const { data, msg }: any = await GETuserinfo(getCookie("Authorization"));
      if (data) {
        store.userinfo = data;
      } else {
        delCookie("Authorization");
        store.userinfo = {};
        ElMessage.error("登录过期");
      }
    } catch (error) {}
  }

  // 开启进度条
  nprogress.start();
  //每次进入页面都显示停在最顶部
  window.scrollTo(0, 0);
  next();
});

router.afterEach((to, from) => {
  // 结束进度条
  nprogress.done();
});

export default router;
