<template>
  <div class="input-search">
    <el-input @blur="onInputValue" ref="element" v-model="inputValue" size="small">
      <template #prepend>
        <Search />
      </template>
    </el-input>
    <el-divider />
    <!-- Tag -->
    <div class="search-history">
      <span class="mr-3" style="font-size: 14px">{{ i18nUtil.T('homeSearch.SearchHistory') }}</span>
      <template v-for="(item, index) in homeStore.searchHistory" :key="item">
        <el-tag @click="onTagClick(item, index)" @close="removeHistoryItem(index)" size="small" closable class="mx-1" effect="dark" round>
          <el-link :underline="false">{{ item }}</el-link>
        </el-tag>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useEventListener } from '@vueuse/core'
import { Search } from '@element-plus/icons-vue'
import { useMainHome } from '@/store/home'
import { i18nUtil } from '@/utils'

const emits = defineEmits<{
  (e: 'onEnterSearch', search: string): void
  (e: 'onInputBlur', search: string): void
}>()

const element = ref()
const inputValue = ref('')
const homeStore = useMainHome()
// 回车
useEventListener(element, 'keydown', (e: KeyboardEvent) => {
  if (e.key !== 'Enter') return
  // if (!inputValue.value) return
  emits('onEnterSearch', inputValue.value)
})
// 点击
const onTagClick = (item: string, index?: number) => {
  inputValue.value = item // 赋值
  if (!inputValue.value) return
  emits('onEnterSearch', inputValue.value)
}
// 移除历史这一项
const removeHistoryItem = (index: number) => {
  homeStore.searchHistory.splice(index, 1)
}
// 失去焦点要做的事情
const onInputValue = () => {
  emits('onInputBlur', inputValue.value)
}
</script>

<style scoped lang="scss">
.input-search {
  margin-bottom: 10px;
  padding-top: 20px;
  :deep(.el-divider--horizontal) {
    margin: 5px;
  }

  :deep(.el-input) {
    --el-input-border-color: none;
    --el-input-hover-border-color: none;

    .el-input__wrapper {
      background: none;
    }

    .el-input__wrapper.is-focus {
      box-shadow: none;
    }

    svg {
      width: 2em;
      height: 2em;
    }
  }

  :deep(.el-tag) {
    background-color: #e6e8eb;
    --el-tag-border-color: none;
    .el-icon {
      color: #666;
    }
  }

  .search-history {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
}
</style>
