// 逻辑很简单，写在同一个文件里面吧
import { ref } from 'vue'
import { AllViewItem, ViewItemInter } from './use-view-item/index'
import { useMainHome } from '@/store/home'

export class Service {
  activeSearch = ref<ViewItemInter[]>(AllViewItem)
  homeStore = useMainHome()
  // 执行搜索
  onEnterSearch = (val: string) => {
    // 过滤出这一项
    this.filterSearchValue(val)
    // 执行历史推入
    this.unshiftHistory(val)
  }
  // 执行过滤
  private filterSearchValue = (val: string) => {
    // 清空
    this.activeSearch.value = []
    // 遍历
    AllViewItem.forEach((item) => {
      // 从二级中过滤出来当前的数据
      const itemx = item.item.filter((itemx) => itemx.itemTitle.includes(val))
      // 拼接数据，完整数据
      if (itemx.length > 0) this.activeSearch.value.push({ title: item.title, item: itemx })
    })
  }
  // 执行推入搜索历史
  private unshiftHistory = (val: string) => {
    // 需要校验数据是否重复
    const isExist = this.homeStore.searchHistory.find((item) => item === val)
    // 没有需要保留
    if (!isExist) {
      // 只保留五项
      if (this.homeStore.searchHistory.length >= 5) this.homeStore.searchHistory.pop()
      // 需要把这个推入历史里面
      this.homeStore.searchHistory.unshift(val)
    }
  }
  // 恢复数据
  public onInputValue = (val: string) => {
    if (!val) this.activeSearch.value = AllViewItem
  }
}
