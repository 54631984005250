<template>
  <el-card v-if="modal" v-on-click-outside="() => (modal = false)" :body-style="{ padding: '30px' }" shadow="never">
    <InputSearch @onEnterSearch="onEnterSearch" @onInputValue="onInputValue" />
    <UseViewSearch :activeSearch="activeSearch" />
  </el-card>
</template>

<script lang="ts" setup>
import { inject } from 'vue'
import InputSearch from './input-search/index.vue'
import UseViewSearch from './use-view-item/index.vue'
import { vOnClickOutside } from '@vueuse/components'

const modal = inject('modal')

import { Service } from './'
const { activeSearch, onEnterSearch, onInputValue } = new Service()
</script>

<style scoped lang="scss"></style>
